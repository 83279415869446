export const sideBarMenu = [
  {
    title: "Analytics",
    objectId: "1",
    icon: "fa-light fa-chart-line",
    pageType: "",
  },
  {
    title: "Preferences",
    objectId: "2",
    icon: "fa-light fa-sliders",
    pageType: "preferences",
  },
  {
    title: "Mail",
    objectId: "3",
    icon: "fa-light fa-envelope",
    pageType: "mail",
  },
  { title: "API", objectId: 3, icon: "fa-light fa-key", pageType: "api" },
  {
    title: "Webhook",
    objectId: "4",
    icon: "fa-light fa-globe",
    pageType: "webhook",
  },
];
export const premiumMenu = [
  {
    title: "File adapter",
    objectId: "5",
    icon: "fa-light fa-file",
    pageType: "fileadapter",
  },
  ...(window.location.origin === "https://staging-console.opensignlabs.com"
    ? [
        {
          title: "Pfx File",
          objectId: "6",
          icon: "fa-light fa-file-certificate",
          pageType: "pfx",
        },
      ]
    : []),
  {
    title: "Invoices",
    objectId: "7",
    icon: "fa-light fa-file-invoice",
    pageType: "invoices",
  },
  {
    title: "Subscriptions",
    objectId: "8",
    icon: "fa-light fa-money-check-dollar",
    pageType: "subscriptions",
  },
  {
    icon: "fa-light fa-people-group",
    title: "Teams",
    target: "_self",
    pageType: null,
    objectId: "9",
    children: [
      {
        title: "Organizations",
        objectId: "9a",
        icon: "fa-light fa-buildings",
        pageType: "organizations",
      },
      {
        title: "OrgAdmins",
        objectId: "9b",
        icon: "fa-light fa-users",
        pageType: "orgadmins",
      },
    ],
  },
];
